<template>
    <section id="activities" class="py-5">
        <the-banner-small>Bijkomende activiteiten</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-12">
                    <h3 class="py-2">Kleding</h3>
                    <p>
                        Sedert het begin onze activiteiten, hebben wij eraan gehouden om naast voeding ook kleding gratis aan te bieden omdat iedereen kleding nodig heeft.
                        Alle klederen welke wij verzamelen worden ons geschonken door particulieren uit Groot-Berlare.
                        Elk kledingstuk wordt apart nagekeken en wat niet perfect draagbaar is, wordt niet weerhouden. Gemiddeld beschikken wij over zowat 600 à 700 kledingstukken voor de 4 seizoenen, voor dames, heren en kinderen.
                        De afdeling kleding is open tijdens de dag van de voedselbedeling (2° donderdag van de maand, zie <router-link to="/bedelingen">Bedelingen</router-link>) en wat men nodig heeft, kan gratis worden meegenomen.
                    </p>
                </div>
                <div class="col-12">
                    <h3 class="py-2">Huishoudtextiel</h3>
                    <p>
                        Gordijnen, overgordijnen, dekens, lakens, dekbed,… zijn niet altijd in voorraad, maar hier geldt in principe dezelfde regeling als voor kleding. Bij beschikbaarheid, kan alles worden meegenomen.
                    </p>
                </div>
                <div class="col-12">
                    <h3 class="py-2">Schoenen</h3>
                    <p>
                        Heel regelmatig krijgt het SMF schoenen van particulieren, welke best nog draagbaar zijn.
                        Er is dan ook altijd een beperkte voorraad schoenen beschikbaar.
                        Zelfde regeling als bij de kleding: wie het schoentje past en nodig heeft, kan/mag het meenemen.
                    </p>
                </div>
                <div class="col-12">
                    <h3 class="py-2">Sint-Maartenfeest</h3>
                    <p>
                        Jaarlijks komt de Sint op bezoek in de week vóór 11 november.
                        Wat niet kon in 2020 (Covid-19) , wordt gehoopt dat de Sint in 2021 wel op bezoek zal kunnen komen. Hij komt voor kinderen onder de 9 jaar, wat voor de kleinsten altijd een verrassend moment is, met een geschenk en chocola en fruit.
                        Daarom vraagt het SMF om alle nog in goede staat zijnde speelgoed, aan het fonds te willen bezorgen. De kindjes zullen er goed mee zijn.
                    </p>
                </div>
                <div class="col-12 text-center">
                    <img src="@/assets/images/gallery/image-10bis.jpg" alt="" class="img-thumbnail p-2" style="max-width: 40%">
                    <img src="@/assets/images/gallery/image-11.jpg" alt="" class="img-thumbnail ml-3 p-2"  style="max-width: 40%">
                </div>
                <div class="col-12">
                    <h3 class="py-2">Groot gratis Kerstfeestdiner</h3>
                        <p>Na het Sint-Maartenfeest van 8 november ll., waarbij de Sint aan 20 kinderen een prachtig geschenk en” nic-nacjes” en mandarijntjes kon geven, zetten wij nu de deuren open naar het jaarlijks terugkerend Groot Gratis Kerstfeest</p>
                        <p>Want december is de kerstmaand, en Kerst is het feest van vrede en Nieuw Licht, van warmte in onze harten, van nieuw leven, van verruimde hoop naar vrede in Oekraïne en in het Midden-Oosten. </p>
                        <p>En ook dit jaar zal het niet anders zijn: samen vieren met mensen die het niet zo breed hebben, op velerlei vlakken. Mensen voor wie ons hart, onze sympathie en onze vriendschap openstaan. Voor hen doen we het. Een beetje in navolging van Hem die het ons leerde. </p>
                        <p>Ons feest gaat door op vrijdag 22 december 2023 vanaf 17.00u in de parochiezaal te Berlare. Maar dit feest is niet gratis: kleine gezinnen (tot 3 pers.) betalen 1 euro per gezin en grotere gezinnen betalen 2 euro per gezin, als deelnamekost. Op deze manier leveren de gezinnen zélf, ook hun bijdrage tot dit feest. </p>
                        <p>Wij serveren: vers gemaakte groentesoep, kalkoenpavé met frietjes en waterkers of appelmoes voor de kinderen, gevolgd door ijstaart en koffie met versnapering. En ook dit jaar krijgen wij op -culinair vlak- de steun van <strong>Restaurant ’t Cuisintje, Dorp 29</strong> te Berlare.
                           De 18 vaste medewerkers van het SMF werken het eten dan verder af en zij serveren aan tafel. Uiteraard hangt aan dit Groot Feestmaal een (geraamd) prijskaartje van ca. 785 €.</p>
                        <p>En om onze kas niet te zwaar te belasten, zoeken wij naar sponsors, naar mensen die onze werking een warm hart toedragen. Gewoonweg om dit feest betaalbaar te houden en te kunnen laten doorgaan...</p>
                        <p>Sponseren kan u door storting op onze bankrekening BE75 7370 2162 2151 van SMF. Informatie wordt u graag gegeven: diaken Raoul Verhaeven 0475/968.982, Willy Van Steen 052/42.62.32, Albertine De Vos 0474/89.81.97, Angèle Van Malderen 0472/25.27.87 87 en Laurette Jacobs 0485/28.64.95.. Wie dit wenst, kunnen wij een attest van sponsoring bezorgen.</p>
                        <p>Onze dank aan alle mensen van vrede en van goede wil, uit naam van hen die weinig hebben.</p>
                </div>
                <div class="col-12">
                    <h3 class="py-2">2-jaarlijkse frituur Sint-Martinus</h3>
                    <p>
                        Tweejaarlijks, ter gelegenheid van de Berlaarse jaarmarkt (september), houdt het SMF frituur Sint-Martinus, een drukke activiteit waar alle medewerkers hun beste beentje voorzetten.
                        Dit gaat door in de parochiezaal in Berlare en kent toch héél wat bijval. Spijtig, maar geen frituur in 2020, om de gekende reden, wat voor het fonds problematisch kan zijn
                        Omwille van gemist opbrengsten.
                    </p>
                    <p>Ook voor 2021 hoopt het SMF op beterschap en op het kunnen organiseren van deze frituur. Het wordt dus ook hier, afwachten.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../components/layout/TheBannerSmall'

export default {
    components: {
        TheBannerSmall
    }
}
</script>

<style>

</style>
